import React from "react";
import Layout from "../../components/Layouts/Layout";

const AvisoLegal = () => {
	return (
		<Layout>
			<h1>Aviso legal</h1>
		</Layout>
	);
};

export default AvisoLegal;
